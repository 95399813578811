import React from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection";
import DecorativeSection from "../components/DecorativeSection";
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"


export const query = graphql`
  {
    gallery: allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
        edges {
          node {
            id
            base
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
  }
`

const SalesPage = ({ data }) => {
  console.log(data);
  return (
    <Layout>
      <Seo title="Promocje" />
      <SmallHeroSection />
      <DecorativeSection />

      <section id="price" className="price">
        <div className="section-title">
          <h2><span>Promocje</span></h2>
        </div>
      </section>

    </Layout>
  )
}

export default SalesPage
