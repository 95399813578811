import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const query = graphql`
{
  file(name: {eq: "decorative-elementor"}) {
    childImageSharp {
      gatsbyImageData(
        formats: WEBP  
        placeholder: BLURRED)
    }
  }
}
`

const DecorativeSection = () => {
  const data = useStaticQuery(query);
  return (
    <SectionDecorative >
      <DecorativeElementor css={{ backgroundImage: `url(${data.file.childImageSharp.gatsbyImageData.images.fallback.src})` }} />
    </SectionDecorative>
  )
}

const DecorativeElementor = styled.div`
  background-position: center center;
    background-repeat: repeat;
    background-size: cover;
    height: 70px;
    margin-top: -70px;
    z-index: 100;
    position: relative;
  `

const SectionDecorative = styled.section`
  padding: 0;
`


export default DecorativeSection;