import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import DecorativeSection from "./DecorativeSection"

const query = graphql`
{
  file(name: {eq: "bg1"}) {
    publicURL
  }
}
`
const SmallHeroSection = () => {
  const data = useStaticQuery(query);
  return (
    <>
    <section id="hero-for-rest-sides">
      <div className="hero-container">
        <div className="hero-item" css={{ backgroundImage: `url(${data.file.publicURL})` }}>
          <div className="h-container">
            <div className="hero-content">
            </div>
          </div>
        </div>
      </div>
    </section>
    <DecorativeSection/>
    </>
  )
}

export default SmallHeroSection;